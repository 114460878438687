<template>
  <div
    :class="['dropbox', maxNum > 1 ? 'paddingDropbox' : '', documentacionValida ? '' : 'dropbox-error error--text']"
    :style="readonly ? 'pointer-events:none' : ''"
  >
    <div
      :class="[
        'dropbox-background',
        !canDrop ? 'remove-drop' : '',
        maxNum > 1 ? 'paddingDropbox' : '',
        hovering ? 'hover' : ''
      ]"
      :style="maxNum > 1 ? { height: value.length * 60 + 60 + 'px' } : {}"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
      @dragover.stop="dragOver"
      @dragleave="dragLeave"
    >
      <input
        type="file"
        :multiple="maxNum > 1"
        :name="uploadFieldName"
        :disabled="!canDrop"
        accept="image/x-png,image/jpeg"
        @change="
          filesChange($event.target.name, $event.target.files)
          $event.target.value = ''
        "
        @drop.stop
        :class="['input-file', !canDrop ? 'remove-drop' : '']"
        :style="{ height: '100%' }"
      />
    </div>
    <v-col cols="auto" v-if="value.length === 0" :class="['dropbox-message', documentacionValida ? '' : 'error--text']">
      <v-icon :class="['dropbox-message', documentacionValida ? '' : 'error--text']" style="font-size:50px;"
        >mdi-upload</v-icon
      >
      <div v-if="readonly">
        Espacio reservado para imágenes
      </div>
      <div v-else>Arrastre {{ maxNum > 1 ? 'archivos' : 'un archivo' }} o haga clic</div>
    </v-col>
    <draggable
      v-if="value.length > 0"
      v-model="uploadedFilesComputed"
      class="image-list"
      v-bind="{ disabled: maxNum === 1 || value.length < 2, forceFallback: true }"
      @start="onStart"
      @end="onEnd"
    >
      <div
        v-for="(item, index) in value"
        :key="item.id ? item.id : item.id_temporal"
        :class="[
          'image-list-item',
          maxNum > 1 && value.length > 1 ? 'draggable' : 'noDrag',
          dragging ? 'hide-remove' : ''
        ]"
        :style="
          index === 0 && maxNum > 1
            ? { 'margin-top': '20px' }
            : index === value.length - 1 && maxNum > 1
            ? { 'margin-bottom': '20px' }
            : {}
        "
      >
        <FilePreview
          :class="[esNoticia ? 'image-list-image-noticia' : 'image-list-image', value.length < 2 ? 'noDrag' : '']"
          :file="item"
          :readonly="readonly"
          @remove="remove(index)"
        ></FilePreview>
      </div>
    </draggable>
  </div>
</template>
<script>
import { upload } from './filePreviewUpload.js'
import FilePreview from './FilePreview'
import uniqid from 'uniqid'
import draggable from 'vuedraggable'
import rulesMixin from '@/mixins/rules.js'

export default {
  components: {
    FilePreview,
    draggable
  },
  mixins: [rulesMixin],
  props: {
    maxNum: {
      default: 1,
      type: Number
    },
    fileType: {
      default: null,
      type: String
    },
    uploadFieldName: {
      default: 'image',
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    value: {
      type: Array
    },
    documentacionValida: {
      default: true,
      type: Boolean
    },
    esNoticia: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      dragging: false,
      hovering: false
    }
  },
  computed: {
    canDrop() {
      return !this.dragging && this.maxNum > this.value.length
    },
    uploadedFilesComputed: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.$emit('input', [])
    },
    remove(index) {
      const newVal = this.value
      newVal.splice(index, 1)
      this.$emit('input', newVal)
      this.dragging = true
      setTimeout(() => {
        this.dragging = false
      }, 100)
    },
    save(formData, file) {
      const newFile = {
        id_temporal: uniqid(),
        status: 'SAVING',
        file: file,
        error: ''
      }
      let newVal = this.value
      newVal.push(newFile)
      this.$emit('input', newVal)

      // upload data to the server
      upload(formData, this.fileType)
        .then(x => {
          this.$set(newFile, 'nombre', x[0].nombre)
          this.$set(newFile, 'mime', x[0].mime)
          this.$set(newFile, 'documentoGrande', x[0].documentoGrande)
          this.$set(newFile, 'extension', x[0].extension)
          this.$set(newFile, 'status', 'SUCCESS')
        })
        .catch(err => {
          this.$set(newFile, 'error', err.message)
          this.$set(newFile, 'status', 'FAILED')
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const filesToUpload = Math.min(fileList.length, this.maxNum - this.value.length)
      if (!fileList.length || filesToUpload <= 0) {
        return
      }
      for (let i = 0; i < filesToUpload; i++) {
        let formData = new FormData()
        formData.append(fieldName, fileList[i], fileList[i].name)
        this.save(formData, fileList[i])
      }
    },
    onStart() {
      this.dragging = true
    },
    onEnd() {
      this.dragging = false
    },
    mouseOver() {
      if (!this.dragging) {
        this.hovering = true
      }
    },
    mouseOut() {
      this.hovering = false
    },
    dragOver() {
      if (!this.dragging) {
        this.hovering = true
      }
    },
    dragLeave() {
      this.hovering = false
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="sass" scoped>

.paddingDropbox.dropbox
  overflow-y: auto
  z-index: 0

.dropbox
  position: relative
  outline: 2px solid #e5e5e5
  padding: 5px 5px
  display: flex
  justify-content: center
  align-items: center
  flex-grow: 1

.dropbox-error
  outline: 2px solid currentColor !important

.dropbox-background
  position: absolute
  top: 0
  left: 0
  min-height: 100%
  width: 100%
  height: 100%
  background-color: white
  background-repeat: repeat
  background-position-x: 20.5px
  background-position-y: 10.25px
  &.hover:not(.remove-drop)
    background-size: 30px 30px
    background-image: linear-gradient(-45deg,#F6F6F6 25%,transparent 26%,transparent 50%,#F6F6F6 50%,#F6F6F6 75%,transparent 76%,transparent)
    animation: stripes 2s linear infinite

.input-file
  opacity: 0
  width: 100%
  height: 100%
  &:not(.remove-drop)
    cursor: pointer

.dropbox .dropbox-message
  margin: 10px
  font-size: 14px
  text-align: center
  justify-content: center
  align-items: center
  z-index: 1
  pointer-events: none

.image-list
  pointer-events: none
  vertical-align: middle
  position: absolute
  z-index: 2
  left: 0
  top: 0
  width: 100%
  height: 100%
  padding-left: 20px
  padding-right: 20px
  padding-top: 15px
  padding-bottom: 15px
  display: flex
  flex-direction: column
  &.noDrag
    pointer-events: none


.image-list-item
  justify-content: center
  pointer-events: visible
  display: flex
  flex-grow: 0
  margin: 5px
  user-select: none
  flex: 1 0 auto
  &.noDrag
    pointer-events: none
  &.noDrag .image-list-image
    pointer-events: visible
  &.noDrag .image-list-image-noticia
    pointer-events: visible

.image-list-image
  display: flex
  align-items: center
  width: 120px
  height: 120px
  position: relative
  background-color: rgb(250, 250, 250)
  outline: 1px solid #e5e5e5

.image-list-image-noticia
  display: flex
  align-items: center
  width: 190px
  height: 190px
  margin-top: -15px
  position: relative
  background-color: rgb(250, 250, 250)
  outline: 1px solid #e5e5e5


.draggable:hover
  cursor: move
</style>
