<template>
  <div>
    <PopUpEditar
      nombre="NOTICIA"
      :accion="accion"
      :tipo="'Noticia'"
      :masculino="false"
      @enviarEditar="enviarEditar()"
      @enviarCrear="enviarCrear()"
      @cancelar="
        $emit('cancelar')
        resetValues()
      "
    >
      <v-container fluid>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="6" class="centeredInput">
              <v-text-field
                v-if="!ver"
                dense
                :readonly="ver"
                label="Título"
                :style="ver ? 'pointer-events:none' : ''"
                :rules="[rules.required, rules.max(100)]"
                v-model="noticiaEditar.title"
              ></v-text-field>
              <h2 v-else>{{ noticiaEditar.title }}</h2>
            </v-col>
            <v-col style="height: 100%;">
              <v-autocomplete
                :items="promociones"
                label="Promoción"
                :readonly="ver"
                v-model="promocionSeleccionada"
                :rules="[rules.required]"
                return-object
                item-text="promotion_desc"
                item-value="promotion_code"
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <v-dialog
                v-if="!ver"
                ref="dialog"
                v-model="modal"
                :rules="[rules.required]"
                :readonly="ver"
                :style="ver ? 'pointer-events:none' : ''"
                :return-value.sync="noticiaEditar.day"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="noticiaEditar.day"
                    label="Seleccione una fecha"
                    class="primary--text"
                    prepend-icon="mdi-calendar-search"
                    append-icon="mdi-chevron-down"
                    readonly
                    :style="ver ? 'pointer-events:none' : ''"
                    :rules="[rules.required]"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="noticiaEditar.date"
                  @input="clickDatePicker($event)"
                  :rules="[rules.required]"
                  :readonly="ver"
                  :disabled="ver"
                  locale="es"
                ></v-date-picker>
              </v-dialog>
              <h2 v-else>{{ noticiaEditar.day | formatDate }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-checkbox v-model="noticiaEditar.url_type" :readonly="ver" label="Incluir enlace en la noticia" />
            </v-col>
            <v-col v-if="noticiaEditar.url_type">
              <v-text-field
                label="URL del enlace"
                :readonly="ver"
                v-model="noticiaEditar.url"
                :rules="[rules.required, rules.max(500)]"
              />
            </v-col>
            <v-col v-if="noticiaEditar.url_type">
              <v-text-field
                label="Texto del botón"
                :readonly="ver"
                v-model="noticiaEditar.button_text"
                :rules="[rules.required, rules.max(20)]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" style="height: 100%;">
              <v-autocomplete
                :items="categorias"
                label="Categoría"
                :readonly="ver"
                :disabled="ver"
                v-model="noticiaEditar.category_id"
                item-text="text"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
              <FileUploader
                :max-number="1"
                class="mt-2"
                :readonly="ver"
                style="height:200px; width: 350px"
                v-model="noticiaEditar.documentacion"
                uploadFieldName="documentacion[]"
                :documentacionValida="true"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
              <v-btn color="primary" @click="pegarTextoPlano()">
                PEGAR TEXTO PLANO
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="space-between" dense>
            <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
              <wysiwyg
                v-model="noticiaEditar.text"
                :readonly="ver"
                label="Texto"
                :style="ver ? 'pointer-events:none' : ''"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </PopUpEditar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEditar from '@/components/generic/PopUpEditar.vue'
import FileUploader from '@/components/generic/fileUploader/FileUploader'
import rulesMixin from '@/mixins/rules'
export default {
  name: 'Noticia',
  mixins: [rulesMixin],
  components: {
    PopUpEditar,
    FileUploader
  },
  props: ['noticia', 'ver', 'accion'],
  data() {
    return {
      noticiaEditar: {
        title: null,
        summary: null,
        text: null,
        fecha: null,
        url: null,
        button_text: null,
        url_type: null,
        documentacion: this.noticia && this.noticia.image ? [this.noticia.image] : []
      },
      valid: false,
      promocionSeleccionada: null,
      promociones: null,
      categorias: null,
      categoriaSeleccionada: null,
      modal: false,
      incluirEnlace: false,
      textoBoton: null,
      urlEnlace: null
    }
  },
  async created() {
    this.promociones = await this.loadPromotions()
    this.categorias = await this.loadCategorias()
    if (this.noticia != null) {
      this.noticiaEditar = JSON.parse(JSON.stringify(this.noticia))
      this.noticiaEditar.url_type = this.noticia.url_type == 1
      this.promocionSeleccionada = this.promociones.find(x => x.promotion_code == this.noticia.promotion_code)
      this.noticiaEditar.documentacion = this.noticia && this.noticia.image ? [this.noticia.image] : []
    }
  },
  computed: {
    ...mapGetters({}),
    cambiado() {
      return this.noticia != null && JSON.stringify(this.noticia) != JSON.stringify(this.noticiaEditar)
    },
    noticiaEnviar() {
      let copy = JSON.stringify(this.noticiaEditar)
      copy = JSON.parse(copy)
      let text = this.quitarFormato(this.noticiaEditar.text)
      copy.text = this.ponerFormato(text)
      copy.summary = this.quitarFormatoResumen(this.noticiaEditar.text).substring(0, 199)
      if (this.noticiaEditar.documentacion.length > 0 && this.noticiaEditar.documentacion[0].file != null) {
        copy.image = this.noticiaEditar.documentacion[0].file
      } else {
        copy.image = null
      }
      copy.documentacion = []
      return copy
    }
  },
  methods: {
    ...mapActions({
      addAlert: 'alerts/add',
      loadPromotions: 'promotions/getPromotions',
      loadCategorias: 'news/getCategories'
    }),
    async pegarTextoPlano() {
      try {
        const text = await navigator.clipboard.readText()
        console.log(text)
        if (text == null || text == '') {
          this.addAlert({ text: 'No tiene nada copiado en el portapapeles', color: 'warning' })
          return
        } else {
          if (this.noticiaEditar.text != null) {
            this.noticiaEditar.text += this.quitarFormato(text)
          } else {
            this.noticiaEditar.text = this.quitarFormato(text)
          }
        }
      } catch (err) {
        console.error('Error al leer el portapapeles:', err)
      }
    },
    ponerFormato(texto) {
      return texto
        ? texto
            .replaceAll('[b]', '<b>')
            .replaceAll('[i]', '<i>')
            .replaceAll('[u]', '<u>')
            .replaceAll('[/b]', '</b>')
            .replaceAll('[/i]', '</i>')
            .replaceAll('[/u]', '</u>')
        : null
    },
    quitarFormatoResumen(texto) {
      return texto
        ? texto
            .replaceAll('[b]', '')
            .replaceAll('[i]', '')
            .replaceAll('[u]', '')
            .replaceAll('[/b]', '')
            .replaceAll('[/i]', '')
            .replaceAll('[/u]', '')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('<div>', '')
            .replaceAll('</div>', '\n')
            .replace(/<[^>]*>/g, '')
        : null
    },
    quitarFormato(texto) {
      return texto
        ? texto
            .replaceAll('<b>', '[b]')
            .replaceAll('<i>', '[i]')
            .replaceAll('<u>', '[u]')
            .replaceAll('</b>', '[/b]')
            .replaceAll('</i>', '[/i]')
            .replaceAll('</u>', '[/u]')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('<div>', '')
            .replaceAll('</div>', '\n')
            .replace(/<[^>]*>/g, '')
        : null
    },
    resetValues() {
      this.noticiaEditar.title = null
      this.noticiaEditar.summary = null
      this.noticiaEditar.fecha = null
      this.noticiaEditar.text = null
      this.promocionSeleccionada = null
      this.noticiaEditar.url = null
      this.noticiaEditar.url_type = false
      this.noticiaEditar.button_text = null
    },
    enviarEditar() {
      this.$refs.form.validate()
      if (this.valid === false) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('editar', this.noticiaEnviar)
    },
    enviarCrear() {
      this.$refs.form.validate()
      if (this.valid === false) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('crear', this.noticiaEnviar)
    },
    clickDatePicker(event) {
      this.$refs.dialog.save(event)
      this.noticiaEditar.day = event
      this.modal = false
    }
  },
  watch: {
    promocionSeleccionada(newVal) {
      console.log(newVal)
      this.noticiaEditar.promotion_code = newVal.promotion_code
      this.noticiaEditar.promotion_desc = newVal.promotion_desc
    }
  }
}
</script>
<style>
.noMostrar .editr--toolbar {
  display: none;
}
.noMostrar .editr {
  border: none;
}
</style>
