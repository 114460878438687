import axios from 'axios'

export const BASE_URL = process.env.VUE_APP_BASE_URL + 'api'
export const IMAGES_URL = process.env.VUE_APP_BASE_URL
export const LOGOTIPO_URL = BASE_URL + '/logotipos/'

export const DOCUMENTOS_URL = BASE_URL + '/documentos/get'
const api = axios.create({
  // withCredentials: true,
  // baseURL: 'https://nuvaLiving.overtek.es/api/'
  // baseURL: 'http://nuvaLivingdes.overtek.es/api/'
  // baseURL: 'http://localhost:8888/nuvaweb/api',
  baseURL: BASE_URL,
  withCredentials: true
})

export default api
