import api from '@/api/api'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getNews({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/news/get_list_news', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async getCategories({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/news/get_list_categories', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async updateNoticia({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.set('noticia', JSON.stringify(obj.noticia))
        if (obj.noticia.image) {
          formData.append('image', obj.noticia.image)
        }
        api
          .post('/cms/news/update_new', formData)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    },
    async deleteNoticia({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/news/delete_new', obj)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    }
  },
  getters: {}
}
